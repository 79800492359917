export const defaultLocale = "en";
export const localeOptions = [
    { id: 'en', name: 'English', icon: 'us' },
    { id: 'es', name: 'Spanish', icon: 'es' },
    { id: 'pt', name: 'Portuguese', icon: 'pt' },
    { id: 'fr', name: 'French', icon: 'fr' },
];

// let IMG_URL = 'https://api.wonhago.com';
// let IMG_URL = 'https://demoapi.wonhago.com';
let IMG_URL = process.env.VUE_APP_API_URL;
console.log("@@@@ IMG_URL : "+IMG_URL);
console.log("@@@@ process.env.NODE_ENV : "+process.env.NODE_ENV);
if (process.env.NODE_ENV === 'development') {
    // IMG_URL = 'http://localhost:3004';
    IMG_URL = 'https://ipapi.wonhago.com';
}
console.log("@@@@1 IMG_URL : "+IMG_URL);
console.log("@@@@1 process.env.NODE_ENV : "+process.env.NODE_ENV);
export const imgUrl = IMG_URL;
