import Vue from "vue";
import EventEmitter from 'events';
const userLogin = 'isLogged';
const loginExpiryKey = 'tokenExpiry';
const Userinfo = 'userInfo';
// const localStorageKey = 'loggedIn';
const tokenKey = 'userToken';
import config from "../../config.json";
import jwt from "jsonwebtoken";
const tokenSecret = config.TOKEN_SECRET;

class Auth extends EventEmitter {
    authToken = null
    userProfile = null
    tokenExpiry = null
    accessCookieTime = '1h';
        // Login With Firebase
    localLogin(authResult) {
        const userToken = authResult.token;
        const manager = jwt.verify(userToken, tokenSecret);
        Vue.$log.debug(['manager', manager, manager.exp, Date.now().toPrecision(1)]);
        this.tokenExpiry = manager.exp;
        Vue.$cookies.set(tokenKey, userToken, this.accessCookieTime);
        localStorage.setItem(loginExpiryKey, this.tokenExpiry);
        localStorage.setItem(userLogin, 'true');
        // localStorage.setItem(localStorageKey, 'true');
        localStorage.setItem(Userinfo, JSON.stringify(manager));
        // localStorage.setItem(Userinfo, JSON.stringify({
        //     displayName: authResult.user.displayName,
        //     email: authResult.user.email,
        //     photoURL: authResult.user.photoURL,
        // }));
        localStorage.setItem(tokenKey, userToken);
    }
    Logout() {
        Vue.$cookies.remove(tokenKey);
        localStorage.removeItem(loginExpiryKey);
        localStorage.removeItem(userLogin);
        localStorage.removeItem(Userinfo);
        localStorage.removeItem(tokenKey);
        // localStorage.removeItem(localStorageKey);

    }

    isAuthenticated() {
        // const t = new Date();
        // const now = (t.getTime() - t.getMilliseconds()) / 1000;
        // const localExpire = localStorage.getItem(loginExpiryKey)
        //
        // const loggedIn = localExpire > now && localStorage.getItem(userLogin) === 'true';
        //
        // if (!loggedIn) {
        //     this.Logout();
        // }
        //
        // return loggedIn;
        const accessToken = Vue.$cookies.get(tokenKey);
        const isAuth = !!accessToken;
        return !!accessToken;
    }
    isAuthenticatedUser() {
        return (
            new Date(Date.now()) < new Date(localStorage.getItem(loginExpiryKey)) &&
            localStorage.getItem(localStorageKey) === 'true'
        );
    }

}

export default new Auth();
