<template>
  <b-modal id="event-view-modal" ok-title="확인" cancel-title="닫기" size="xl" title="IP 정보 보기" cancel-variant="dark" @ok="editEvent" >
    <div class="card card-default ">
      <div class="card-body">
        <div class="row">
          <div class="col-6 ">
            <!--                    <div class="row py-1">-->
            <!--                        <div class="col-3">-->
            <!--                            <h5>브랜드</h5>-->
            <!--                        </div>-->
            <!--                        <div class="col">{{item.brand_name}}</div>-->
            <!--                    </div>-->
            <div class="row form-group">
              <div class="col-3">
                <h5>IP 제목</h5>
              </div>
              <div class="col"><input type="text" class="form-control" name="event_name" :value="item.event_name"></div>
            </div>
            <div class="row form-group">
              <div class="col">
                <h5>IP 이미지(가로형:PC버전)</h5>
              </div>
            </div>
            <div class="row form-group">
              <div class="col text-center" v-if="item.mission_image">
                <img :src="imgUrl+item.mission_image" class="w-50"/>
              </div>
            </div>
            <div class="row form-group">
              <div class="col">
                <h5>IP 이미지(세로형:모바일버전)</h5>
              </div>
            </div>
            <div class="row form-group">
              <div class="col text-center" v-if="item.mission_image2">
                <img :src="imgUrl+item.mission_image2" class="w-50"/>
              </div>
            </div>
            <div class="row form-group">
              <div class="col">
                <h5>NFT 이미지</h5>
              </div>
            </div>
            <div class="row form-group">
              <div class="col text-center" v-if="item.image_path">
                <img :src="imgUrl+item.image_path+item.event_code+'.'+item.image_ext" class="w-50">
              </div>
            </div>
            <div class="row form-group">
              <div class="col-3">
                <h5>기본설명</h5>
              </div>
              <div class="col">{{item.event_desc}}</div>
            </div>

            <!--                    <div class="row form-group">-->
            <!--                        <div class="col-3">-->
            <!--                            <h5>팀여부</h5>-->
            <!--                        </div>-->
            <!--                        <div class="col">{{item.team_type}}</div>-->
            <!--                    </div>-->
            <div class="row form-group">
              <div class="col-3">
                <h5>보상유형</h5>
              </div>
              <div class="col">{{item.reward_type_name}}</div>
            </div>
            <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
            <div class="row form-group" v-if="item.reward_type == 'REWARD_TYPE01'" v-for="reward in item.rewards"  v-bind:key="reward">
              <div class="col-3"></div>
              <div class="col-3"><b>{{reward.rank_start}}위까지</b></div>
              <div class="col">{{reward.reward_item}}</div>
            </div>
            <div class="row form-group" v-if="events.reward_type == 'REWARD_TYPE02'" v-for="reward in item.rewards" v-bind:key="reward">
              <div class="col-3"></div>
              <div class="col-3"><b>{{reward.rank_start}} ~ {{reward.rank_end}}위</b></div>
              <div class="col">{{reward.reward_item}}</div>
            </div>
            <div class="row form-group">
              <div class="col-3">
                <h5>목표인원수</h5>
              </div>
              <div class="col">{{Number(item.target_number).toLocaleString()}} 명</div>
            </div>
            <div class="row form-group">
              <div class="col-3">
                <h5>판매 희망가</h5>
              </div>
              <div class="col">{{Number(item.payment).toLocaleString()}}원</div>
            </div>
            <div class="row py-1 form-group">
              <div class="col-3">
                <h5>IP유형</h5>
              </div>
              <div class="col">{{item.event_type_name}}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="row form-group">
              <div class="col-3">
                <h5>IP 상태</h5>
              </div>
              <div class="col">
                <b-select id="eventStatus" name="event_status" class="form-control" v-model="item.event_status" :options="codes" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-3">
                <h5>판매기간</h5>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-6">
                    <date-picker v-model="item.start_date" format="YYYY.MM.DD" type="date" valueType="format" lang="ko"   >
                      <!--                      <template v-slot="{ inputValue, inputEvents }">-->
                      <!--                        <input-->
                      <!--                            class="py-1 w-auto border rounded focus:outline-none focus:border-blue-300"-->
                      <!--                            :value="inputValue"-->
                      <!--                            v-on="inputEvents"-->
                      <!--                        />-->
                      <!--                      </template>-->
                    </date-picker> ~
                  </div>
                  <div class="col-6">
                    <date-picker v-model="item.end_date" type="date" valueType="format" format="YYYY.MM.DD" lang="kr" >
                      <!--                      <template v-slot="{ inputValue, inputEvents }">-->
                      <!--                        <input-->
                      <!--                            class="d-flex px-2 py-1 border rounded focus:outline-none focus:border-blue-300"-->
                      <!--                            :value="inputValue"-->
                      <!--                            v-on="inputEvents"-->
                      <!--                        />-->
                      <!--                      </template>-->
                    </date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row form-group">
              <div class="col">
                <h5>IP 설명이미지</h5>
              </div>
            </div>
            <div class="row form-group">
              <div class="col text-center" v-if="item.detail_image">
                <img :src="imgUrl+item.detail_image" class="w-50">
              </div>
            </div>
            <!--                        <div class="row py-1">-->
            <!--                            <div class="col-3"></div>-->
            <!--                            <div class="col-3"><b>가중치 1</b></div>-->
            <!--                            <div class="col">설명</div>-->
            <!--                        </div>-->
            <!--                    <div class="row py-2">-->
            <!--                        <div class="col-3">-->
            <!--                            <h5>IP조각수</h5>-->
            <!--                        </div>-->
            <!--                        <div class="col">{{item.block_count}}</div>-->
            <!--                    </div>-->
            <!--                    <div class="row ad-blocks">-->
            <!--                        <div class="col-4" v-for="(block, index ) in events.blocks">-->
            <!--                            <img :src="'/storage'+block.qr_image" class="w-100">-->
            <!--                            <div class="form-control" v-if="events.event_type == 'event_type02'">가중치 {{index+1}}</div>-->
            <!--                            <div class="pl-4" v-if="events.event_type == 'event_type02'">{{block.level_explain}}</div>-->
            <!--                        </div>-->
            <!--                    </div>-->

          </div>
        </div>
        <div class="row form-group">
          <div class="col">
            <div class="row form-group">
              <div class="col-auto">
                <h5>블럭정보</h5>
              </div>
              <!--                <form action="/qrDown" id="qrDownForm" name="down_form" method="post">-->
              <!--                  <input type="hidden" name="_token" id="csrfToken">-->
              <!--                  <input type="hidden" name="event_hash" v-model="item.event_hash">-->
              <!--                  <input type="hidden" name="image_path" v-model="item.image_path">-->
              <!--                </form>-->
              <!--                <div class="col">-->
              <!--                  <button class="btn btn-outline-success btn-sm" @click="qrDownload(item.event_hash, item.event_name)">QR 인쇄용 PDF 다운로드</button>-->
              <!--                </div>-->
            </div>
            <div class="row form-group">
              <div class="col">
                <div class="position-absolute bg-light w-100 text-center py-5 mt-3" v-show="is_show">
                    <span class=" text-center">
                        <div id="qrCode" class="w-75 m-auto" v-html="selectedQr"></div>
                    </span>

                  <button class="btn btn-primary" @click="closeQr">닫기</button>
                </div>
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th width="20%">블록</th>
                    <th width="10%">수집유형</th>
                    <th width="10%">문서유형</th>
                    <th width="30%">블록설명<br>
                      링크URL</th>
                    <th width="30%">QR코드(클릭시 확대)<br>Short Link</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="block in blockList"  v-bind:key="block">
                    <td>{{block.block_order+1}}번
                      <div><img :src="imgUrl + item.image_path + (block.block_order+1) + '.' + item.image_ext" class="w-50"></div>
                    </td>
                    <td>{{block.block_type_str}}</td>
                    <td>{{block.etc1_type_str}}</td>
                    <td><input
                        class="form-control"
                        id="validationCustom2"
                        type="text"
                        required=""
                        v-model="block.level_explain"
                    />
                      <input type="text" name="etc_explains[]" class="form-control d-none" :class="{ 'd-block' : block.etc_explain}" v-model="block.etc_explain">

                    </td>
                    <td><img :src="imgUrl + block.qr_image" class="w-25" @click="viewQr"
                             v-if="block.block_type == 'BLOCK_TYPE01' || block.block_type == 'BLOCK_TYPE02' || block.block_type == 'BLOCK_TYPE04'">
                      <br>{{block.web_url}}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!--                            <div class="row py-1" v-for="block in events.blocks">-->
                <!--                                <div class="col">{{block.block_order}}번 블럭</div>-->
                <!--                                <div class="col">수집유형 : {{block.block_type_str}}</div>-->
                <!--                                <div class="col-2"><img :src="'/storage'+events.image_path+'/QR/'+block.block_order+'.png'" class="w-100" @click="viewQr"  v-if="block.block_type == 'BLOCK_TYPE01' || block.block_type == 'BLOCK_TYPE02' || block.block_type == 'BLOCK_TYPE04'"></div>-->
                <!--                                <div class="col">{{block.web_url}}</div>-->
                <!--                                <div class="col">{{block.level_explain}}</div>-->
                <!--                                <div class="col">{{block.etc_explain}}</div>-->
                <!--                            </div>-->

              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </b-modal>
</template>

<script>
import {imgUrl} from '../../constants/config';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';

import nodeApi from "../../api/nodeApi";

export default {
  name: "EventViewModal",
  components: { DatePicker },
  props: {
    item: {
      event_name: '',
      event_desc: ''
    },
    blockList: []
  },
  data() {
    return {
      imgUrl: imgUrl,
      codes: [
        {value: 'EVENT_STATUS01', text: '진행대기'}
        ,{value: 'EVENT_STATUS02', text: '진행중'}
        ,{value: 'EVENT_STATUS03', text: '종료'}
      ],
      is_show: false,
      selectedQr: '',
    }
  },
  created() {
    this.$log.debug(['EventViewModal', this.item]);
  },
  methods: {
    viewQr(obj) {
      this.$log.debug(['viewQr', obj, this.is_show])
      // $('#qrCode').html(obj.target.outerHTML);
      this.selectedQr = obj.target.outerHTML
      this.is_show = !this.is_show;
    },
    closeQr() {
      this.is_show = !this.is_show;
      this.selectedQr = '';
      // this.qr = '';
    },
    editEvent() {
      this.$log.debug(['editEvent', this.item, this.blockList]);
      let param = this.item;
      param.blockList = this.blockList;
      nodeApi.putEvent(param).then( res => {
        if (res.data.success) {
          nodeApi.showToast(res.data.msg, "success");
        }
      }).catch( err => {
        nodeApi.showToast(res.data.msg);
      })
    }
  }
}
</script>

<style scoped>
.mx-datepicker {
  width: 8rem;
}
</style>
