import Vue from "vue";
import axios from "axios";
import userAuth from "../auth/index.js";

const accessCookieTime = userAuth.accessCookieTime;

axios.interceptors.request.use((config) => {

    config.headers.Authorization = 'Bearer ' + Vue.$cookies.get('userToken');
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    Vue.$log.debug(['nodeApi interceptor', response.headers['x-auth-token']]);

    // token 시간을 증가시켜준다?
    const accessToken = response.headers['x-auth-token'];
    if (accessToken) {
        Vue.$log.debug(['refresh token', accessToken]);
        Vue.$cookies.set('userToken', accessToken, accessCookieTime);
    }

    return response;
}, (error) => {
    return Promise.reject(error);
})

// let API_URL = 'https://api.wonhago.com';
// let API_URL = 'https://demoapi.wonhago.com';
let API_URL = process.env.VUE_APP_API_URL;

let IMG_URL = API_URL;
console.log("@@@@ API_URL : "+API_URL);
console.log("@@@@ IMG_URL : "+IMG_URL);
console.log("@@@@ process.env.NODE_ENV : "+process.env.NODE_ENV);

if (process.env.NODE_ENV === 'development') {
    API_URL = '';
    IMG_URL = 'http://localhost:3004';
}
console.log("@@@@2 API_URL : "+API_URL);
console.log("@@@@2 IMG_URL : "+IMG_URL);
console.log("@@@@2 process.env.NODE_ENV : "+process.env.NODE_ENV);

export default {
    showToast(msg, type, duration) {
        Vue.toasted.show(msg, {
            theme: "bubble",
            position: "top-center",
            type: type ? type : 'error',
            duration: duration ? duration : 2000
        });
    },
    postManagerLogin(param) {
        return axios.post(API_URL + '/admin/managerLogin', param);
    },
    postEventList(param) {
        console.log("@@@@ API_URL+/admin/eventList : "+API_URL + '/admin/eventList');
        return axios.post(API_URL + '/admin/eventList', param);
    },
    postEvent(param) {
        return axios.post(API_URL + '/admin/event', param, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    putEvent(param) {
        return axios.put(API_URL + '/admin/event', param);
    },
    fileUpload(param) {
        console.log(['fileUpload ', param]);
        return axios.post(API_URL + '/admin/fileUpload', param, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    postGoodsList(param) {
        return axios.post(API_URL + '/admin/goodsList', param);
    },
    postGoods(param) {
        return axios.post(API_URL + '/admin/goods', param, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    putGoods(param) {
        return axios.put(API_URL + '/admin/goods', param);
    },
    postBlockList(param) {
        return axios.post(API_URL + '/admin/blockList', param);
    },
    managerCreate(param) {
        return axios.post(API_URL + '/admin/managerCreate', param);
    }
}
