<template>
  <form
    class="form-horizontal auth-form"
    @submit.prevent="handleSubmit"
    method="post"
  >
    <div class="form-group">
      <ValidationProvider rules="required|email" v-slot="{ errors }" name="이메일">
      <input
        v-model="manager_email"
        name="login[manager_email]"
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        placeholder="이메일을 입력하세요"
      />
        <span class="validate-error ml-4">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <div class="form-group">
      <ValidationProvider rules="required" v-slot="{ errors }" name="비밀번호">
      <input
        :type="type"
        v-model="password"
        name="login[manager_password]"
        class="form-control"
        placeholder="비밀번호를 입력하세요"
      />
      </ValidationProvider>
    </div>
    <div class="form-terms">
      <div class="custom-control custom-checkbox mr-sm-2">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customControlAutosizing"
        />
        <label class="custom-control-label" for="customControlAutosizing"
          >Remember me</label
        >
        <a href="#" class="btn btn-default forgot-pass">lost your password</a>
      </div>
    </div>
    <div class="form-button">
      <button class="btn btn-primary" type="submit" @click="login">
        Login
      </button>
    </div>
    <div class="form-footer">

    </div>
  </form>
</template>

<script>
import userAuth from "../../auth/index.js";
import nodeApi from "../../api/nodeApi";
export default {
  components: {},
  data() {
    return {
      type: "password",
      manager_email: "",
      password: "",
      submitted: false
    };
  },
  created() {
    // reset login status for JWT
    this.$store.dispatch("authentication/logout");
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
    },
    login() {
      this.submitted = true;

      let data = {
        manager_email: this.manager_email,
        password: this.password
      };

      if (this.manager_email && this.password) {
        nodeApi.postManagerLogin(data).then( res => {
          this.$log.debug(['postManagerLogin res', res]);
          if (res.data.success) {
            userAuth.localLogin(res.data);
            this.$router.push("/");
          } else {
            nodeApi.showToast(res.data.msg);
          }
        }).catch( err => {
          this.$log.error(['로그인 err', err]);
          nodeApi.showToast('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        });
      } else {
        nodeApi.showToast('이메일과 비밀번호는 반드시 입력해야합니다.');
        this.$log.debug(['로그인 안됨'])
      }





    }
  }
};
</script>
