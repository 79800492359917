import Vue from "vue";
import nodeApi from "../../api/nodeApi";

const state = {
    goodsList: [],


}

const getters = {
    getGoodsList: (state) => {
        return state.goodsList;
    }
}

const actions = {
    getGoodsList: (context, payload) => {

        // Vue.$log.debug(['getEventList action ', payload]);

        nodeApi.postGoodsList(payload).then( res => {
            Vue.$log.debug(['store postGoodsList res', res]);
            if (res.data.success) {
                context.commit('getGoodsList', res.data.goods_list);
            }
        }).catch( err => {
            Vue.$log.error(err.toString());
        });
    }
}

const mutations = {
    getGoodsList: (state, payload) => {
        state.goodsList = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
